<template>
  <section class="container">
    <div class="vista-encabezado justify-content-sm-between mb-3">
      <div
        v-if="estadoSeleccionado"
        class="col col-md-auto text-center mb-3 mb-md-0"
      >
        <mdb-btn
          dark-waves
          flat
          icon="arrow-left"
          title="Regresar"
          class="m-0"
          @click="
            municipioSeleccionado ? municipioSeleccionado = '' : estadoSeleccionado = '';
            estadoSeleccionado ? titulo = `Municipios de ${estadoSeleccionado}` : titulo = 'Estados';
            eliminarAgg()"
          >
          Regresar
        </mdb-btn>
      </div>
      <header :class="['col-12 col-md pl-2 h5-responsive titulo', {'ml-md-4 ml-lg-0 text-md-left': !estadoSeleccionado}]">
        Zonas
      </header>
      <div class="col-auto boton-primario">
        <mdb-btn
          color="primario"
          icon="plus"
          @click="modal.estado ? modal.estado = false : modal.estado = true; modal.accion = 'Agregar'; selecZonas()"
        >
          Agregar zonas
        </mdb-btn>
      </div>
    </div>
    <div class="row justify-content-center">
      <ul class="col-12 col-md-4 mx-md-2 table t-clientes">
        <li class="encabezado sticky">
          <header class="h4-responsive text-center text-md-left">{{ titulo }}</header>
          <div class="thead claro">
            <div class="col">Nombre</div>
            <div class="col">N° de Clientes</div>
            <div
              v-if="municipioSeleccionado"
              class="col-6"
            >
              Acción
            </div>
          </div>
        </li>
        <template v-if="!estadoSeleccionado && !cargando">
          <li
            v-for="estados in zonas"
            :key="estados.est_zon"
            class="contenido cursor-pointer"
            @click="
              estadoSeleccionado = estados.est_zon;
              titulo = `Municipios de ${estadoSeleccionado}`
            "
          >
            <div
              class="col"
              data-columna="Nombre"
            >
              <p class="text-capitalize">{{ estados.est_zon }}</p>
            </div>
            <div
              class="col"
              data-columna="N° de Clientes"
            >
              <p>{{ estados.clientes }}</p>
            </div>
          </li>
        </template>
        <template v-if="estadoSeleccionado && !municipioSeleccionado && zonas[estadoSeleccionado] && !cargando">
          <li
            v-for="municipios in zonas[estadoSeleccionado].municipios"
            :key="municipios.ciu_zon"
            class="contenido cursor-pointer"
            @click="municipioSeleccionado = municipios.ciu_zon; titulo = `Parroquias de ${municipioSeleccionado}, ${estadoSeleccionado}`"
          >
          <div
            class="col"
            data-columna="Nombre"
          >
            <p class="text-capitalize">{{ municipios.ciu_zon }}</p>
          </div>
          <div
            class="col"
            data-columna="N° de Clientes"
          >
            <p>{{ municipios.clientes }}</p>
          </div>
          </li>
        </template>
        <template v-if="municipioSeleccionado && zonas[estadoSeleccionado] && !cargando">
          <li
            v-for="parroquias in zonas[estadoSeleccionado].municipios[municipioSeleccionado].parroquias"
            :key="parroquias.sec_zon"
            class="contenido cursor-pointer"
          >
            <div
              class="col"
              data-columna="Nombre"
            >
              <p class="text-capitalize">{{ parroquias.sec_zon }}</p>
            </div>
            <div
              class="col"
              data-columna="N° de Clientes"
            >
              <p>{{ parroquias.clientes }}</p>
            </div>
            <div class="col-12 col-sm-6 pr-0 pl-sm-0" data-columna="Acción">
              <mdb-btn
                flat
                dark-waves
                icon="pen"
                class="m-0 py-2 px-1 px-sm-3 "
                @click="selecZonas(parroquias.sec_zon); modal.estado ? modal.estado = false : modal.estado = true; modal.accion = 'Editar'; idPa = parroquias.id_zon_pa"
              >
                Editar
              </mdb-btn>
              <mdb-btn
                flat
                dark-waves
                icon="trash"
                class="m-0 py-2 px-1 px-sm-3 "
                @click="modalConfirmacionEliminar = true; zonaParaEliminar = `${parroquias.sec_zon}, ${municipioSeleccionado}, ${estadoSeleccionado}`; idPa = parroquias.id_zon_pa"
              >
                Eliminar
              </mdb-btn>
            </div>
          </li>
        </template>
        <template v-else-if="cargando">
          <li class="mensaje-no-items py-3">
            <LoaderKel />
            <p class="texto mt-2">
              Cargando
            </p>
          </li>
        </template>
      </ul>
      <div class="d-none d-sm-block col-auto col-lg">
        <MapaZonas :zoom-zona="estadoSeleccionado"/>
      </div>
    </div>
    <mdb-modal
      centered
      elegant
      scrollable
      :show="modal.estado"
      @shown="!aggEst.length ? $refs.inputEstado.focus() : !aggMun.length ? $refs.inputMunicipio.focus() : $refs.inputParroquia.focus()"
      @close="modal.estado = false; modal.accion=''; validarValores = {}"
    >
      <mdb-modal-header>
        <mdb-modal-title>
          {{ modal.accion }} zona
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <form
          class="text-center"
          @submit.prevent="modal.accion === 'Agregar' ? aggZona() : editarZon()"
        >
          <mdb-input
            v-model.trim="aggEst"
            ref="inputEstado"
            label="Estado"
            :class="['mt-0 mb-3', validarValores.estado]"
            outline
          />
          <mdb-input
            v-model.trim="aggMun"
            ref="inputMunicipio"
            label="Municipio"
            :class="['my-2', validarValores.municipio]"
            outline
          />
          <mdb-input
            v-model.trim="aggPa"
            ref="inputParroquia"
            label="Parroquia"
            :class="['my-3', validarValores.parroquia]"
            outline
          />
          <mdb-btn
            :title="modal.accion === 'Agregar' ? 'Añadir nueva zona' : 'Actualizar zona'"
            color="primario"
            :icon="modal.accion === 'Agregar' ? 'check' : 'sync'"
            type="submit"
            class="mt-4"
          >
            {{ modal.accion === 'Agregar' ? modal.accion : 'Actualizar' }}
          </mdb-btn>
        </form>
      </mdb-modal-body>
    </mdb-modal>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
    <ConfirmacionEliminar
      :boton-deshabilitado="botonDeshabilitado"
      :mostrar-modal="modalConfirmacionEliminar"
      :mensaje="`Esta acción eliminará la zona: ${zonaParaEliminar}`"
      @cerrar="modalConfirmacionEliminar = false, zonaParaEliminar = ''"
      @confirmar="eliminarZon()"
    />
  </section>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import { capitalizarPalabras } from '@/funciones/funciones.js'
import {
  mdbBtn,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import MapaZonas from '@/components/MapaZonas.vue'
import ConfirmacionEliminar from '@/components/ConfirmacionEliminar.vue'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Zonas',
  components: {
    mdbBtn,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    ConfirmacionEliminar,
    MapaZonas,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      titulo: 'Estados',
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      modalConfirmacionEliminar: false,
      zonas: {},
      idPa: '',
      estadoSeleccionado: '',
      municipioSeleccionado: '',
      modal: {
        estado: false,
        accion: ''
      },
      aggEst: '',
      aggMun: '',
      aggPa: '',
      validarValores: {},
      zonaParaEliminar: '',
      cargando: false
    }
  },
  methods: {
    cargaInicial () {
      this.cargando = true
      apiPost({ s: 'zonasMv' }, '')
        .then((res) => {
          this.enlistarZonas(res.data.zon)
        })
    },
    enlistarZonas (data) {
      const estados = {}
      data.forEach(e => {
        if (e.eli_zon === '1') {
          if (!estados[e.est_zon]) {
            estados[e.est_zon] = {
              est_zon: e.est_zon,
              id_zon_es: e.id_zon,
              clientes: 0,
              municipios: {}
            }
          }
          estados[e.est_zon].clientes += Number(e.num_clientes)
          if (!estados[e.est_zon].municipios[e.ciu_zon]) {
            estados[e.est_zon].municipios[e.ciu_zon] = {
              ciu_zon: e.ciu_zon,
              id_zon_mu: e.id_zon,
              clientes: 0,
              parroquias: {}
            }
          }
          estados[e.est_zon].municipios[e.ciu_zon].clientes += Number(e.num_clientes)
          if (!estados[e.est_zon].municipios[e.ciu_zon].parroquias[e.sec_zon]) {
            estados[e.est_zon].municipios[e.ciu_zon].parroquias[e.sec_zon] = {
              sec_zon: e.sec_zon,
              id_zon_pa: e.id_zon,
              clientes: 0
            }
          }
          estados[e.est_zon].municipios[e.ciu_zon].parroquias[e.sec_zon].clientes += Number(e.num_clientes)
        }
      })
      this.zonas = estados
      this.cargando = false
    },
    aggZona () {
      this.validarValores = {
        estado: this.aggEst && this.aggEst.length && this.validarTexto(this.aggEst) ? 'valido' : 'invalido',
        municipio: this.aggMun && this.aggMun.length && this.validarTexto(this.aggMun) ? 'valido' : 'invalido',
        parroquia: this.aggPa && this.aggPa.length && this.validarTexto(this.aggPa) ? 'valido' : 'invalido'
      }
      if (!this.aggEst || !this.aggMun || !this.aggPa) {
        this.alertaMensaje = {
          contenido: 'Asegurese de rellenar todos los campos',
          tipo: 'error'
        }
        return
      }
      if (!this.validarTexto(this.aggEst, this.aggMun, this.aggPa)) {
        this.alertaMensaje = {
          contenido: 'Los campos deben ser solo texto',
          tipo: 'advertencia'
        }
        return
      }
      if (!this.validarZonas(this.aggEst, this.aggMun, this.aggPa)) {
        this.validarValores = {
          estado: 'invalido',
          municipio: 'invalido',
          parroquia: 'invalido'
        }
        this.alertaMensaje = {
          contenido: 'Esta zona ya está agregada',
          tipo: 'advertencia'
        }
        return
      }
      apiPost({ s: 'zonAggM' }, 'pai=' + 'Venezuela' + '&edo=' + this.aggEst + '&ciu=' + this.aggMun + '&sec=' + this.aggPa)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.validarValores = {}
            this.modal.estado = false
            this.cargaInicial()
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema',
              tipo: 'error'
            }
          }
        })
    },
    eliminarZon () {
      apiPost({ s: 'zonEliM' }, 'zon=' + this.idPa)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.zonaParaEliminar = ''
            this.estadoSeleccionado = ''
            this.municipioSeleccionado = ''
            this.titulo = 'Estados'
            this.modalConfirmacionEliminar = false
            this.cargaInicial()
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema',
              tipo: 'error'
            }
          }
        })
    },
    editarZon () {
      this.validarValores = {
        estado: this.aggEst && this.aggEst.length && this.validarTexto(this.aggEst) ? 'valido' : 'invalido',
        municipio: this.aggMun && this.aggMun.length && this.validarTexto(this.aggMun) ? 'valido' : 'invalido',
        parroquia: this.aggPa && this.aggPa.length && this.validarTexto(this.aggPa) ? 'valido' : 'invalido'
      }
      if (!this.aggEst || !this.aggMun || !this.aggPa) {
        this.alertaMensaje = {
          contenido: 'Asegurese de rellenar todos los campos',
          tipo: 'error'
        }
        return
      }
      if (!this.validarTexto(this.aggEst, this.aggMun, this.aggPa)) {
        this.alertaMensaje = {
          contenido: 'Los campos deben ser solo texto',
          tipo: 'advertencia'
        }
        return
      }
      if (!this.validarZonas(this.aggEst, this.aggMun, this.aggPa)) {
        this.validarValores = {
          estado: 'invalido',
          municipio: 'invalido',
          parroquia: 'invalido'
        }
        this.alertaMensaje = {
          contenido: 'Esta zona ya está agregada',
          tipo: 'advertencia'
        }
        return
      }
      apiPost({ s: 'zonModM' }, 'pai=' + 'Venezuela' + '&est=' + this.aggEst + '&ciu=' + this.aggMun + '&sec=' + this.aggPa + '&ide=' + this.idPa)
        .then((res) => {
          if (res.data.r) {
            this.alertaMensaje = {
              contenido: 'Operación exitosa',
              tipo: 'correcto'
            }
            this.estadoSeleccionado = ''
            this.municipioSeleccionado = ''
            this.modal.estado = false
            this.validarValores = {}
            this.cargaInicial()
          } else {
            this.alertaMensaje = {
              contenido: 'Ha ocurrido un problema',
              tipo: 'error'
            }
          }
        })
    },
    validarTexto (est, mun, pa) {
      this.aggEst = capitalizarPalabras(this.aggEst)
      this.aggMun = capitalizarPalabras(this.aggMun)
      this.aggPa = capitalizarPalabras(this.aggPa)

      const texto = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/

      return (texto.test(est) && texto.test(mun) && texto.test(pa))
    },
    validarZonas (est, mun, pa) {
      if (this.zonas[est] && this.zonas[est].municipios[mun]) {
        for (const p in this.zonas[est].municipios[mun].parroquias) {
          if (p.toUpperCase() === pa.toUpperCase()) {
            return false
          }
        }
      }
      return true
    },
    selecZonas (nomPa) {
      if (nomPa) {
        this.aggEst = this.estadoSeleccionado
        this.aggMun = this.municipioSeleccionado
        this.aggPa = nomPa
      } else {
        this.aggEst = this.estadoSeleccionado
        this.aggMun = this.municipioSeleccionado
        this.aggPa = ''
      }
    },
    eliminarAgg () {
      this.aggEst = this.estadoSeleccionado
      this.aggMun = this.municipioSeleccionado
      this.aggPa = ''
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>
<style lang="scss" scoped>
.table.t-clientes {
  max-width: 320px;

  .contenido > [class*=col] {
    line-height: 1.3;

    &::before {
      align-self: center;
      min-width: 60px;
      width: 60px !important;
    }
  }

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);

  // Anchos específicos de columnas
  @media screen and (min-width: $breakpointToChange) {
    .acciones {width: 140px;}
  }
}
</style>
